import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as math from 'mathjs'; 
import './wz.scss';

class PrintWz extends Component {
    constructor(props){
        super(props);
        this.state = {
            areaAdres: '',
            areaAdresNabywca:''
        }
    }
    evaluate = (inputValue) => {
        try {
            const parts = inputValue.split('=');
            const expressionBeforeEquals = parts[0];
            const cleanedExpression = expressionBeforeEquals.replace(/[^()\d/*+.]/g, '');
            const result = math.evaluate(cleanedExpression);
            return typeof result === 'number' && !isNaN(result) ? result : '';
        } catch (error) {
            return '';
        }
    };
    handleChange = (event) => {
        this.setState({areaAdres: event.target.value});
        this.setState({areaAdresNabywca: event.target.value})
    }
    handleChangeNabywca = (event) => {
        this.setState({areaAdresNabywca: event.target.value})
}


    render() {
        let year = new Date().getFullYear(),
            mouth = new Date().getMonth() + 1,
            day = new Date().getDate();
        let formatDay = day < 10 ?  "0"+day : day,
            formatMouth = mouth < 10 ?  "0"+mouth : mouth
        const column = this.props.data.map((item) => (
            <tr key={item._id}>
                <td><textarea type="text" name="input-text1"  defaultValue={item.name} className="opis"></textarea></td>
                <td><textarea type="text" name="input-text2"  defaultValue={item.title} className="opis"></textarea></td>
                <td><textarea type="text" name="input-text3"  defaultValue={item.subcount} className="opis"></textarea></td>
                <td><textarea type="number" name="input-text4"  defaultValue={this.evaluate(item.count)} className="opis"></textarea></td>
            </tr>
        ));

        return (
            <div className="document">
                <div className='logo-wz'>
                    <h2>Nadawca</h2>
                    <textarea className='nadawca'
                       defaultValue={"CGS Drukarnia sp. z o.o. \nul.Towarowa 3, Mrowino k.Poznania\n62-090 Rokietnica\nNIP:779-16-32-331, REGON:630878953"}>
                    </textarea>
                </div>
                <div className='logo-adress'>
                    <h2>Adres dostawy</h2>
                    <textarea className='nadawca' 
                              type="text" 
                              name="input-text1" 
                              value={this.state.areaAdres}
                              onChange={this.handleChange}
                              ></textarea>
                </div>
                <h2>Dokument WZ</h2>
                <div className="nadawca-info">
                    <div className="nadawca-info_nabywca">
                        <div className="nadawca-info_nabywca-dane">Dane nabywcy</div>
                    </div>
                    <div className="nadawca-info_title">
                        <h4>Data wysyłki : {`${formatDay}.${formatMouth}.${year}`} </h4>
                        <textarea type="text" 
                              name="input-text1" 
                              className="input_nabywca"
                              value={this.state.areaAdresNabywca}
                              onChange={this.handleChangeNabywca}></textarea>
                    </div>
                </div>
                <table className="table-wz">
                    <thead>
                        <tr>
                            <th>Nr. Zlecenia</th>
                            <th>Tytuł</th>
                            <th>Opis</th>
                            <th>Dostarczone</th>
                        </tr>
                    </thead>
                    <tbody>
                        {column}
                    </tbody>
                </table>
                <footer>
                    <div className='footer-item'>
                        <div className='footer'><p>Odebrał(a):</p></div>
                        <div className='footer'><p>Wystawił(a):</p></div>
                        <div className='footer'><p>Łączny nakład:</p></div>
                    </div>
                    <div className='footer-comment'>
                        W przypadku stwierdzenia uszkodzenia zabezpieczenia przesyłki lub jej zawartości prosimy o odpowiednią adnotację na dokumencie towarzyszącym
                    </div>
                </footer>
            </div>
        );
    }
}

export default PrintWz;
