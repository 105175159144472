import { Route, Routes, Navigate } from 'react-router-dom';
import { useState } from 'react';
import Login from './component/app-login/Login';
import AppMagaz from './component/magaz';

import "./app.css"

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
   const [user, setUser] = useState(null);

  const giveToken = (token) => {
    setToken(token);
    setIsAuthenticated(true);
  };
  
    const giveUser = (user) => {
    setUser(user);
  
  };

  return (
    <div className="App_login">
      <Routes>
        <Route
          exact
          path="/"
          element={
            token === localStorage.token ? (
              <Navigate to="/app" />
            ) : (
              <Login giveToken={giveToken} giveUser={giveUser} />
            )
          }
        />
        <Route
          exact
          path="/app"
          element={
            token === localStorage.token ? (
              <AppMagaz user={user}/>
            ) : (
              <Navigate to="/" />
            )
          }
        />
      </Routes>
    </div>
  );
}

export default App;



