import React, { useState } from "react";
import axios from 'axios';

import "./auth.scss"

const Auth = (props) => {
    const [pass, setPass] = useState("");
    const [username, setUserName] = useState("");

    const handleLogin = () => {
        const data = { username: username, pass: pass };
        loginToApp(data);
      }
    
      const loginToApp = (data) => {
        axios.post('https://api.magazyn-stan.fun/api/login', data, ) // Замініть URL на правильний шлях до вашого серверу
          .then(response => {
            // console.log('Дані успішно відправлені на сервер:', response.data);
            const token = response.data.token;
            if (token) {
              
              localStorage.setItem('token', token);
              props.giveToken(token);
              props.giveUser(response.data.user);
              // Додайте будь-який додатковий код, який вам потрібно виконати після успішної аутентифікації
            }
          })
          .catch(error => {
            // console.error('Помилка відправки даних на сервер:', error);
            window.alert("Uzytkownik nie istnieje")
          });
      };

    return (
        <>
         <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h2 className="card-title">Logowanie</h2>
                <form>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label"></label>
                    <input
                      type="username"
                      className="form-control"
                      id="username"
                      placeholder="Login"
                      value={username.toLowerCase()}
                      onChange={(e) => setUserName((e.target.value))}
                    />
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      placeholder="Haslo"
                      value={pass}
                      onChange={(e) => setPass(e.target.value)}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleLogin}
                  >
                    Zaloguj się
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
        
    )
}

export default Auth;