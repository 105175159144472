import { Component } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import "./info-pak.scss";

import springer from './info-img/macmillan.jpg';
import macmillan from './info-img/macmilPol.jpg';
import vanin from './info-img/van.jpg';
import arvato from './info-img/arvatokarton.jpg';




class  Info extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
      
    }
    render() {
        return (
            <div className='packing'>
            <h1>Standardy pakowania</h1>
                <h2>Springer</h2>
                <ul>
                    <li>- palety EURO </li>
                    <li>- maksymalna wysokość 140cm </li>
                    <li>- maksymalna waga 700kg </li>
                    <li>- karty paletowe 2 po krótkim boku</li>
                    <li>- Ilość w kartonach wedlug etykiet (stała ilość) </li>
                    <li>- Karty paletowe wydrukowane przez intro, zawierające ISBN, nr.Imp, tytuł, ilość </li>
                    <li>- Nie dajemy nadwyżki</li>
                </ul>
                <img src={springer} alt='springer'></img>
                <h2>Macmillan</h2>
                <ul>
                    <li>- palety EURO </li>
                    <li>- maksymalna wysokość 140cm </li>
                    <li>- maksymalna waga 700kg </li>
                    <li>- kartonowe krawedzie + folia</li>
                    <li>- karty paletowe 2 po krótkim boku ( karty od handlowca)</li>
                    <li>- Paczki układamy kodem kreskowym do góry </li>
                    <li>- stała ilość paczek na warstwe - 13  </li>
                    <li>- Nadwyżka - dokladamy </li>
                </ul>
                <img src={macmillan} alt='macmillan'></img>
                <h2>VanIn</h2>   
                <ul>
                    <li>- palety 100x80 </li>
                    <li>- maks wysokość 120cm </li>
                    <li>- 2 karty paletowe po krótkim boku </li>
                    <li>- Nie dajemy nadwyżki</li>
                </ul>
                <img src={vanin} alt='vanin'></img>
                <h2>GeraNova</h2>
                <ul >
                    <li>- paleta max 1,2 m wraz z paleta</li>
                    <li>- waga max 900 kg</li>
                    <li>- tektura na dole palety i gorze</li>
                    <li>- tektura pomiedzy kazda warstwa</li>
                    <li>- kartonowe krawedzie + folia</li>
                    <li>- pokrywa (dekiel) z gory palety spięta pasami 2 pasy z kazdej strony</li>
                    <li>- na kazdym krotkim boku etykieta paletowa z Nr. ISBN </li>
                    <li>- pakowanie luzem na paletach</li>
                    <li>- Nadwyżka - dokładamy maks. 2 % od nakładu</li>
                </ul> 
                <h2>Arvato</h2>
                <ul>
                    <li>- pakowanie w kartony MAX 10 kg (tolerancja do 11kg) i 15 cm wysokości</li>
                    <li>- liczba egzemplarzy w kartronie MUSI byc podzielna przez 10</li>
                    <li>- ewentualne wypelnianie kartonu TYLKO papierem</li>
                    <li>- etykieta kartonowa z systemu</li>
                    <li>- przekladka papaierowa pomiedzy kazda warstwa kartonow</li>
                    <li>- europalety certyfikowane wymienialne</li>
                    <li>- wysokosc palety optymalnie 1,35 m</li>
                    <li>- waga 700 kg</li>
                    <li>- paleta musi byc rowna, nic nie moze wystawac (maja automat mierzacy wymiary palety)</li>
                    <li>- etykieta paletowa wsuwana za ostatnia warstwe folii - nie uzywamy taśmy klejacej.</li>
                    <li>- na gorze palety karton + folia</li>
                    <li>- Nie dajemy nadwyżki</li>
                </ul>
                <img src={arvato} alt='arvato'></img>
                <h2>Grupa MAC</h2>
                <h3>Proszę zobligować odpowiedzialnych za poniższe zadania pracowników do
                sprawdzenia:</h3>
                <ul >
                    <li>1. czy wysyłany towar znajduje się WYŁĄCZNIE na paletach euro
                        (oryginalnych)</li>
                    <li>2. czy etykiety transportowe klienta są adekwatne do tytułu
                        znajdującego się na palecie (etykieta po jednym egzemplarzy na dwóch bokach
                        palety, pod ostatnią warstwę folii)</li>
                    <li>3. Czy „paczki” są ułożone w pakiet po 10egz. Luzem na palecie /
                        warstwy przełożone kartonowymi arkuszami</li>
                    <li>4. Czy paleta nie jest wyższa niż 130cm. z paletą.</li>
                    <li>5. Czy waga palety nie przekracza 700kg.</li>
                    <li>6. Czy towar na palecie nie wystaje poza format palety.</li>
                    <li>7. Czy na warstwie jest po 14 paczek.</li>
                    <li>8. Zabezpieczanie palety do transportu – wg wymogów GE</li>
                    <li>9. Nadwyżki dokladamy</li>
                </ul> 
                
                    <h2> Fresh Print, MM Print, Print Media </h2>
                <ul>
                    <li>- Żadnych oznaczeń z naszym logo</li>
                </ul>
                    <h2>Wydawnictwo STARK</h2>
                <ul>
                    <li>- Książki spakowane w karton - pakujemy standartowo</li>
                    <li>- Książki luzem pakujemy z deklem ściągnięte pasami </li>
                    <li>- wysokość do 120cm </li>
                </ul>
                    <h2>Ameet</h2>
                <ul>
                    <li>- Książki spakowane w karton - pakujemy standartowo</li>
                    <li>- Książki luzem zakładamy kartonowe krawedzi na górę palety </li>
                    <li>- waga optymalne 600kg </li>
                    <li>- Nie dajemy nadwyżki</li>
                </ul>
                    <h2>Helion</h2>
                <ul>
                    <li>- zakładamy dekiel drewiany przy wysyłce DPD </li> 
                    <li>- waga optymalne 500kg - przy wysyłce DPD </li>
                    <li>- waga do 700kg </li>
                    <li>- Nie dajemy nadwyżki</li>
                </ul>
            
                                <h2>Kooperacja</h2>
                <ul>
                    <li>- Schopa - zakładamy dekiel drewiany przy wysyłce DPD  </li> 
                    <li>- Follak - naklejki i tłoczenie zakładamy dekiel drewiany </li>
                    <li>- waga do 700kg </li>
                </ul>
                                                <h2>MARTEL</h2>
                <ul>
                    <li>- waga do 700kg </li>
                    <li>- kartonowe krawedzie + folia</li>
                </ul>
            </div>
    );
    }
}
export default Info;