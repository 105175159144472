 
import './work-table.scss'
import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import html2canvas from 'html2canvas';
import axios from 'axios';
import Modal from 'react-modal';
import PrintButton from './PrintButton';
import * as math from 'mathjs'; 
import pencil from"./img/pencil.png";
import truck from"./img/delivery-truck.png";
import recycle from"./img/recycle-bin.png";
import clipboard from"./img/clipboard.png";
import email from"./img/email.png";
import copy from"./img/copy.png";
import printer from"./img/printer.png";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import {BiSolidChevronsLeft ,BiSolidChevronsRight, BiShare,BiX,BiMailSend, BiSolidClinic} from "react-icons/bi";

class WorkTable extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      editableRowIndex: -1,
      addJob:{ id: '', order: 'actuality'},
      editedData: [...this.props.data],
      newRow:{ order: 'actuality',id :'', name :'', title: '', count : '', subcount: ''},
      searchTerm: '',
      inputValue: '',
      user: this.props.user, 
      selectedOption:'name',
      checkboxState: false,
      copy:false,
      ModalIsOpen:false,
      bodyTxt:'',
      emails : [
        'karolina.jandy@cgs.pl',
        'barbara.koscielniak@cgs.pl',
        'logistyka@cgs.pl',
        'introligatornia@cgs.pl',
        'danuta.pawlicka@cgs.pl',
        'maciej.janiszewski@cgs.pl',
        'magdalena.filipowska@cgs.pl',
        'agnieszka.kubacka@cgs.pl',
        'rafal.krajewski@cgs.pl',
        'joanna.walkowiak@cgs.pl',
        'szymon.hajkowski@cgs.pl'
      ],
      selectedEmail:'',
      selectedRows:[],
      currentPage: this.props.listItem,
      itemsPerPage: 100,
      printWZ:false,
      typeDoc:'',
      result:[]
    };
  }
  // API
componentDidMount  ()  {
    Modal.setAppElement(document.getElementById('root'));
  }
  
  sendDataToServer = (data) => {
  axios.post(`${this.props.host}/api/post`, data)
    .then(response => {
    //   console.log('Дані успішно відправлені на сервер:', response.data);
    this.setState(prevState => ({
        editedData: [...prevState.editedData, response.data]
      }));
    this.props.newUpData(response.data)
    })
    .catch(error => {
    //   console.error('Помилка відправки даних на сервер:', error);
    window.alert('Wystapil blad, sprawdź połączenie z internetem')
    });
};
  
  // // Метод для обробки змін у this.props.data
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ editedData: [...this.props.data] });
    }
  };
  
  handleOrderChange = (index) => {
    const updatedData = [...this.state.editedData];
    updatedData[index].order = 'wyslane'
    this.setState({ editedData: updatedData });
  };
  handleOrderReturn = (index) => {
    const updatedData = [...this.state.editedData];
    updatedData[index].order = 'actuality'
    this.setState({ editedData: updatedData });
  };
  handleDelChange = (index) => {
    const updatedData = [...this.state.editedData];
    let warm = window.confirm("Czy na pewno chcesz usunąć?");
    const deleteDB =this.props.deleteBD;
    if (warm) {
      // Видалення елемента із бази даних
      deleteDB(this.state.editedData[index]._id);
      this.props.deleteById(this.state.editedData[index]._id)
      // Оновлення стану компонента без видаленого елемента
      updatedData.splice(index, 1);
      this.setState({ editedData: updatedData });
    }
  
  };
 
  handleEdit = (index, field, newValue) => {
    const updatedData = [...this.state.editedData];
    updatedData[index][field] = newValue;
    this.setState({ editedData: updatedData })
  };

  handleFieldChange = (index, field, event) => {
    if (index === -1) {
      this.setState({
        newRow: { ...this.state.newRow, [field]: event.target.value }    
      });
    } else {
      this.handleEdit(index, field, event.target.value);
    }
  };

  startEdit = (index) => {
    this.setState({ editableRowIndex: index });
  };

  finishEdit = () => {
    this.setState({ editableRowIndex: -1 });
  };

  addRow = async () => {
    // Створюємо новий об'єкт на основі введених даних
    let newId = this.props.data.map(item => item.id.toString())[0];

    const newItem = {
      id: newId,
      name: this.state.newRow.name,
      title: this.state.newRow.title,
      count: this.state.newRow.count,
      subcount: this.state.newRow.subcount,
      order: 'actuality',
    };

      // Оновлюємо стан компонента, додаючи новий об'єкт
    // Викликаємо функцію батьківського компонента для оновлення даних в ньому
    await this.sendDataToServer(newItem)

  };

  // handleSearch = (searchTerm) => {
  //   this.setState({ searchTerm });
    
  // };

  
  
handleInputChange = (event) => {
this.setState({ inputValue: event.target.value.toLowerCase() });
}

handleEmailChangeMail(selected) {
    const selectedEmail = selected[0]; // Припускаємо, що Typeahead дозволяє вибирати лише один варіант
    this.setState({selectedEmail:selectedEmail})
}

handleInputChangeMail(event) {
    // Оновлюємо введений текст у стані
    this.setState({ selectedEmail: event});
}
  
handleInputBody = (event) => {
this.setState({ bodyTxt: event.target.value });
}

  // Функція для обробки кліку на кнопку
  handleButtonClick = () => {
    const newItem = {
      id: this.state.inputValue,
      order: 'actuality',
      name: '',
      title: '',
      count: '',
      subcount: '',

    };
  
    // Оновлюємо стан з новим об'єктом
    this.sendDataToServer(newItem)
    this.setState({ inputValue: '' });
  }

  handleOrderChangeAndSave = (index, itemId) => {
    // Змініть стан для відображення змін у компоненті
    
    let warm = window.confirm("Zlecenie zostało wysłane?");
    let year = new Date().getFullYear(),
        mouth = new Date().getMonth() + 1,
        day = new Date().getDate();
    let formatDay = day < 10 ?  "0"+day : day,
        formatMouth = mouth < 10 ?  "0"+mouth : mouth
    // Змініть `order` на сервері, передавши `itemId`
    const updatedData = { order: 'wyslane' , date: `${formatDay}.${formatMouth}.${year}`}; // Нове значення `order`
    // Змініть `order` на сервері, передавши `itemId`
    if(warm) {
      this.handleOrderChange(index) ;
      this.props.deleteById(itemId);
      this.props.editItem(itemId, updatedData) ;

    } 
  };

  handleOrderChangeReturn = (index, itemId) => {
    // Змініть стан для відображення змін у компоненті
    this.handleOrderReturn(index);
    let warm = window.confirm("Cofnac zlecenie z wyslanych?");
    // Змініть `order` на сервері, передавши `itemId`
    const updatedData = { order: 'actuality' }; // Нове значення `order`
    if(warm) {
    this.props.editItem(itemId, updatedData);
    }
  };
  
        //calculator

  calculateSubcountAll = (data) => {
    let sum = 0;
  
    // Проходження по всіх об'єктах у масиві
    data.forEach((obj) => {
      // Перетворення значення "subcount" в число та додавання до суми
      sum += parseInt(obj.subcount, 10) || 0;
    });
    return sum;
  }

    // Функція для виконання пошуку
  handleSearch = (value) => {
    this.setState({ searchTerm: value }, () => {
      this.performSearch(value);
    });
  };

    performSearch = (searchTerm) => {
      const { selectedOption } = this.state;
  
      if (searchTerm.length > 0) {
        axios.get(`${this.props.host}/get/search`, {
          params: {
            term: searchTerm,
            option: selectedOption, // Додаємо опцію пошуку (name або date)
          },
        })
        .then(response => {
          this.setState({ result: response.data }); // Зберігаємо результати
          console.log(response.data)
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
      } else {
        this.setState({ results: [] }); // Очистити результати, якщо рядок пошуку порожній
      }
    };

  handleRadioChange = (event) => {
    this.setState({ selectedOption: event.target.value });
};

handleCheckboxChange = () => {
  this.setState({checkboxState:!this.state.checkboxState}) // Перевернути поточний стан
};


editDataFromServer = ( newData) => {
  axios.put(`${this.props.host}/api/editcalculate`, newData)
    .then(response => {
      // Опціонально: обробка відповіді сервера після успішного видалення
    //   console.log('Дані успішно змінені:', response.data);
    })
    .catch(error => {
       window.alert('Wystapil blad')
    //   console.log('Помилка редагування даних:', error);
    });
};

takeScreenshotCopy = () => {
    this.setState({copy:true})
  if (this.tableRef.current) {
    const hideOnPrintElements = this.tableRef.current.querySelectorAll('.hide-on-print');
    const textElements = this.tableRef.current.querySelectorAll('td');
    hideOnPrintElements.forEach(element => {
      element.style.display = 'none';
    });
    
      // Збільшуємо текст перед створенням скріншоту
      textElements.forEach(element => {
        element.style.fontSize = '16px'; // або інший розмір, який вам підходить
      });

    html2canvas(this.tableRef.current).then(canvas => {
      // Перетворюємо canvas на зображення
      const imageURL = canvas.toDataURL('image/png');

      // Створюємо тимчасовий <img> елемент
      const img = new Image();
      img.src = imageURL;
      img.onload = () => {
        // Створюємо тимчасовий <div> для зображення
        const tempDiv = document.createElement('div');
        tempDiv.contentEditable = true;
        tempDiv.appendChild(img);

        // Додаємо тимчасовий елемент до DOM
        document.body.appendChild(tempDiv);

        // Виділяємо вміст тимчасового <div> і копіюємо його в буфер обміну
        const range = document.createRange();
        range.selectNodeContents(tempDiv);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        try {
            document.execCommand('copy');
        }catch(error) {
            alert('Upss, spróbuj jeszcze')
        }
        // Видаляємо тимчасовий елемент з DOM
        document.body.removeChild(tempDiv);
        this.setState({copy:false})
      };
    });

    // Відновлюємо відображення стовпців "hide-on-print"
    hideOnPrintElements.forEach(element => {
      element.style.display = '';
    });
      // Відновлюємо оригінальний розмір тексту
    textElements.forEach(element => {
    element.style.fontSize = ''; // або оригінальний розмір
    });
  }
};

  sendEmail = (imageURL, email, subject, bodyTxt) => {
    // Створюємо об'єкт даних для відправки на сервер
    const data = {
      imageData: imageURL,
      email: email,
      subject: subject,
      bodyTxt:bodyTxt
    };
  
    // Відправляємо POST-запит на сервер за допомогою Axios
    axios.post(`${this.props.host}/post/send-email`, data)
    .then(response => {
      alert('udalo się')
    })
    .catch(error => {
      console.error('Error:', error); // Вивести помилку в консоль
       alert('ups, sprobuj jeszcze')
    });
  };

  takeScreenshot = () => {
    if (this.tableRef.current) {
      const hideOnPrintElements = this.tableRef.current.querySelectorAll('.hide-on-print');
      hideOnPrintElements.forEach(element => {
        element.style.display = 'none';
      });
  
      html2canvas(this.tableRef.current).then(canvas => {
        // Перетворюємо canvas на зображення
        const imageURL = canvas.toDataURL('image/png');
  
        // Створюємо тимчасовий <img> елемент
        const img = new Image();
        img.src = imageURL;
        img.onload = () => {
          // Створюємо тимчасовий <div> для зображення
          const tempDiv = document.createElement('div');
          tempDiv.contentEditable = true;
          tempDiv.appendChild(img);
  
          // Додаємо тимчасовий елемент до DOM
          document.body.appendChild(tempDiv);
  
          // Виділяємо вміст тимчасового <div> і копіюємо його в буфер обміну
          const range = document.createRange();
          range.selectNodeContents(tempDiv);
          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(range);
          try {
            document.execCommand('copy');
            hideOnPrintElements.forEach(element => {
              element.style.display = '';
            });
          } catch (error) {
          };
          // Видаляємо тимчасовий елемент з DOM
          document.body.removeChild(tempDiv);
  
          // Отримуємо адресу електронної пошти та тему з форми
          const email = this.state.selectedEmail;
          const subject = `${this.state.editedData[0].id}:
          ${this.calculateSubcountAll(this.state.editedData)} pal.`;
          const bodyTxt = this.state.bodyTxt;
  
          // Відправляємо дані на сервер разом із URL-адресою зображення скріншоту
          this.sendEmail(imageURL, email, subject, bodyTxt);
          this.setState({ModalIsOpen:false});
          this.setState({selectedEmail:''});
          this.setState({bodyTxt:''})
        };
      });
    }
  };
  
evaluate = (inputValue) => {
    try {
      // Розділяємо вираз по знаку "="
      const parts = inputValue.split('=');
      let expressionBeforeEquals = parts[0]; // Вираз перед "="

      // Видаляємо все в дужках
      expressionBeforeEquals = expressionBeforeEquals.replace(/\([^()]*\)/g, '');

      // Видаляємо всі символи крім цифр та арифметичних операторів
      const cleanedExpression = expressionBeforeEquals.replace(/[^()\d/*+.]/g, '');

      // Обчислюємо очищений вираз
      const result = math.evaluate(cleanedExpression);
      
      return typeof result === 'number' && !isNaN(result) ? result : '';
    } catch (error) {
      return '';
    }

  };
  
toggleRowSelection = (id) => {
    this.setState((prevState) => {
      const selectedRows = prevState.selectedRows.includes(id)
        ? prevState.selectedRows.filter((rowId) => rowId !== id)
        : [...prevState.selectedRows, id];
      return { selectedRows };
    });
  };
  
calculateSelectedSubcountSum = () => {
    const { selectedRows } = this.state;
    const { editedData } = this.state; // Припускаючи, що ваші елементи передаються як пропси
    return selectedRows.reduce((sum, rowId) => {
      const item = editedData.find(item => item._id === rowId);
      return item ? sum + parseFloat(item.subcount || 0) : sum;
    }, 0);
  };
  
  getPageData = (data, page, itemsPerPage) => {
    const start = page * itemsPerPage;
    const end = start + itemsPerPage;
    return data.slice(start, end);
  };

  handleSearchChange = (e) => {
    this.setState({ searchTerm: e.target.value});
    this.props.listItemEdit(0)
  };

  goToNextPage = () => {
    const { data } = this.props;
    if (this.props.listItem < Math.ceil(data.length / this.state.itemsPerPage) - 1) {

      this.props.listItemEdit(this.props.listItem + 1 )
    }
  };

  goToPreviousPage = () => {
    if (this.props.listItem > 0) {
      this.props.listItemEdit(this.props.listItem - 1 )
    }
  };
  
  getSelectedData = () => {
    const { editedData, selectedRows } = this.state;
    const selectedData = editedData.filter(item => selectedRows.includes(item._id));
    return selectedData;
  };
  
  render() {
    const selectedData = this.getSelectedData(); 
    const subcountSum = this.calculateSelectedSubcountSum();
    const {  itemsPerPage, searchTerm, editedData, selectedOption, checkboxState} = this.state;
    const allData = this.state.result;
    const currentPage = this.props.listItem;
    // Фільтрація даних
    let filteredData;
    if (searchTerm && !checkboxState) {
      filteredData = editedData.filter((item) => {
        if (item[selectedOption] !== undefined && item[selectedOption].toLowerCase().includes(searchTerm.toLowerCase())) {
          return true;
        }
        return false;
      });
    } else if (searchTerm && checkboxState && searchTerm.length > 3) {
      filteredData = allData.filter((item) => {
        if (item[selectedOption] !== undefined && item[selectedOption].toLowerCase().includes(searchTerm.toLowerCase())) {
          return true;
        }
        return false;
      });
    } else {
      filteredData = editedData;
    }

    // Дані для поточної сторінки
    const pageData = this.getPageData(filteredData, currentPage, itemsPerPage);
  
  
    // eslint-disable-next-line array-callback-return
  const rows = pageData.map((item, index) => {
      if (item.order === 'actuality' && this.state.searchTerm === ''  && this.state.user !== 'manager') {
      return (
        <>
        <tr key={item._id }
            className={this.state.selectedRows.includes(item._id) ? 'checked-tr' : ''}>
          <td  className="selected" onClick={() => this.toggleRowSelection(item._id)} >
            {index + 1}
          </td>
          <td className="fw700 title-copy"  >
            {this.state.editableRowIndex === index ? (
              <input
                type="text"
                maxLength={6}
                value={item.name }
                onChange={(e) => this.handleFieldChange(index, 'name', e)}
              />
            ) : (
              item.name
            )}
          </td>
          <td className="title-copy"  >
            {this.state.editableRowIndex === index ? (
              <input
                type="text"
                maxLength={58}
                value={item.title}
                onChange={(e) => this.handleFieldChange(index, 'title', e)}
              />
            ) : (
              item.title
            )}
          </td>
          <td  title={this.evaluate(item.count)} >
            {this.state.editableRowIndex === index ? (
              <input
                type="text"
                maxLength={58}
                value={item.count}
                onChange={(e) => this.handleFieldChange(index, 'count', e)}
              />
            ) : (
              item.count
            )}
          </td > 
          <td  className="table__center">
            {this.state.editableRowIndex === index ? (
              <input
                maxLength={6}
                value={item.subcount}
                onChange={(e) => this.handleFieldChange(index, 'subcount', e)}
              />
            ) : (
              item.subcount 
            )}
          </td>
          <td  className="table__center table__center-btn hide-on-print" data-clipboard="false" >
            {this.state.editableRowIndex === index ? (
              <button className="table_btn hide-on-print" title="zapisz" onClick={() => {this.finishEdit(); this.props.editItem(item._id , item)}}><img src={clipboard} alt="zapisz"/></button>
            ) : (
              <button className="table_btn hide-on-print"  title="redaguj" onClick={() => this.startEdit(index)}><img src={pencil} alt="redaguj"/></button>
            )}
            <button className="table_btn hide-on-print" title="wysłano" 
            onClick={() => {this.handleOrderChangeAndSave(index, item._id);
                    }}> <img src={truck} alt="wysłano"/> </button>
            <button className="table_btn hide-on-print" title="usuń" onClick={() => this.handleDelChange(index)}><img src={recycle} alt="usuń"/></button>
          </td>
        </tr>
              </>
      );
    }else if (item.order === 'wyslane' && this.state.searchTerm === ''  && this.state.user !== 'manager') {return (
      <>
        <tr key={item._id} title={item.id} >
          <td >{index+1}</td>
          <td className="fw700" >{item.name}</td>
          <td >{item.title}</td>
          <td > {item.count}</td>
          <td className="table__center">{item.subcount}</td>
          <td className="table__center hide-on-print" data-clipboard="false">{item.date } 
            <button className="table_btnm-left" title="cofnij wysylke"
                onClick={() => {this.handleOrderChangeReturn(index, item._id) }}
                               ><BiShare/></button>
        </td>
        </tr>
      </>
    
    )}else if (this.state.searchTerm !== '' && item.order === 'wyslane' ) {
      return (
        <>
          <tr key={item._id} title={item.id} >
            <td>{index + 1}</td>
            <td className="fw700" >{item.name}</td>
            <td>{item.title}</td>
            <td>{item.count}</td>
            <td className="table__center">{item.subcount}</td>
            <td className="table__center hide-on-print" data-clipboard="false">{item.date}</td>
          </tr>
        </>
      );

    }else if (this.state.searchTerm !== '' && item.order === 'actuality'  ) {
      return (
        <>
          <tr key={item._id}>
            <td>{index + 1}</td>
            <td className="fw700">{item.name}</td>
            <td>{item.title}</td>
            <td>{item.count}</td>
            <td className="table__center">{item.subcount}</td>
            <td className="table__center hide-on-print" data-clipboard="false">{item.id}</td>
          </tr>
        </>
      );

    }else if ( item.order === 'wyslane' && this.state.user === 'manager' && this.state.searchTerm === '' ) {
      return (
        <>
          <tr key={item._id}>
            <td>{index + 1}</td>
            <td className="fw700">{item.name}</td>
            <td>{item.title}</td>
            <td>{item.count}</td>
            <td className="table__center">{item.subcount}</td>
            <td className="table__center hide-on-print" data-clipboard="false">{item.date}</td>
          </tr>
        </>
      );

    }else if ( item.order === 'actuality' && this.state.user === 'manager' && this.state.searchTerm === '') {
      return (
        <>
          <tr key={item._id}>
            <td>{index + 1}</td>
            <td className="fw700">{item.name}</td>
            <td>{item.title}</td>
            <td>{item.count}</td>
            <td className="table__center">{item.subcount}</td>
            <td className="table__center hide-on-print" data-clipboard="false"> - </td>
          </tr>
        </>
      );

    }else {
      return (
        <></>
      )
    }
    });
    const addOrderBtn = () => {
      if(this.state.inputValue === ''|| this.state.user === 'manager') {
        return <></>
      }else {
        return (<button onClick={this.handleButtonClick}>Dodaj tablice</button>)
      }
    }

    const btn = () => {
      if(this.state.editedData[0].order === 'actuality'  && this.state.user !== 'manager' && this.state.searchTerm === '') {
        return (
          <div>
            <button className='table__btn'onClick={this.addRow}><BiSolidClinic /></button>
          </div>

        )
      }else{
        <></>
      }

    }
    
    const table = () => {
      if(editedData.length !== 0 && this.state.printWZ === false){
      return (
    <div ref={this.tableRef}>
    <div className="table__item">{this.state.searchTerm === '' ? `${this.state.editedData[0].id}:
    ${this.calculateSubcountAll(this.state.editedData)} pal.` :  `wyszukiwanie: ${this.calculateSubcountAll(filteredData)} pal. `  } </div>
        <div className='table_btn-copy' >
            <button  className="copy_btn hide-on-print" title="Wyślij email" onClick={() => this.setState({ModalIsOpen:true})}> <img src={email} alt="email"/></button> 
            <button  className="copy_btn hide-on-print" title="Drukuj" onClick={() => window.print()}><img src={printer} alt="print"/></button>
            {this.state.copy ? (<Spinner animation="border"  variant="warning"/>) :<button className="copy_btn hide-on-print" title="Kopiuj" onClick={this.takeScreenshotCopy}><img src={copy} alt="copy"/></button>}
        </div>
      <table className="table" id='your-table-id'>
        <thead>
          <tr key={'table'} className="table__style">
            <th className="table__r1 table__center br-right20">Nr.</th>
            <th className="table__r2">Zlecenie</th>
            <th className='table__r3'>Tytul</th>
            <th className="table__r4">Ilość</th>
            <th className="table__center">Palety</th>
            <th className="table__center br-left20 hide-on-print" data-clipboard="false">Action</th>
          </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
        <div className="hide-on-print" >{btn()}</div>
        {this.state.editedData.length > 50 ? (<div className='prev-next'>
          <button onClick={this.goToPreviousPage} disabled={this.props.listItem === 0}><BiSolidChevronsLeft /></button>
          1...<strong>{this.props.listItem + 1}</strong>...{Math.ceil(filteredData.length / itemsPerPage)}
          <button onClick={this.goToNextPage} disabled={this.props.listItem >= Math.ceil(filteredData.length / itemsPerPage) - 1}><BiSolidChevronsRight /></button>
        </div>) : <></> }
      </div>
      )}else if (editedData.length !== 0 && this.state.printWZ === true){
        return <PrintButton data={selectedData.length > 0 ? selectedData : this.state.editedData} typeDoc={this.state.typeDoc}/>
      } else {
        return <></>;
      }
    }
    
    const miniModal = () => {
         if(this.state.editableRowIndex !== -1) { 
            return (<div className="fixModal"> Suma: {this.evaluate(this.state.editedData[this.state.editableRowIndex].count) } </div> )
    }else { return ( <div> </div> )}
    }
      return (
        <div className='table__style' >
            {miniModal()}
            <div className='input__style'>
              <div className='add_table'>
                <input
                className='input_table'
                type="text"
                maxLength={20}
                placeholder='Dodaj tablice'
                value={this.state.inputValue}
                onChange={this.handleInputChange}
                />
                {addOrderBtn()}
              </div>
                <div>
                {this.state.printWZ === false ? 
              <button className="print-btn_wz" onClick={() => this.setState({ printWZ: !this.state.printWZ , typeDoc:'wz'})} >
              <span> W-Z</span></button> : 
              <button className="print-btn_wz"  onClick={() => this.setState({ printWZ: !this.state.printWZ })} > <BiShare /></button>}
                              {this.state.printWZ === false ? 
              <button className="print-btn_wz" onClick={() => this.setState({ printWZ: !this.state.printWZ , typeDoc:'cmr'})} >
              <span> CMR</span></button> : <></>}
              </div>

              {this.state.editedData.some(item => this.state.selectedRows.includes(item._id)) ?
                  <div className="subcount-send">
                    Do wysyłki : {subcountSum} pal.</div> : <></> }

             <div className='table__serch'>
                <div className='serch-flex'>
                <form className='serch-form'>
                  <input
                      type='radio'
                      value='name'
                      checked={this.state.selectedOption === 'name'}
                      onChange={this.handleRadioChange}
                  />
                  <input
                      className='input_form-check'
                      type='radio'
                      checked={this.state.selectedOption === 'date'}
                      value='date'
                      onChange={this.handleRadioChange}
                  />
                </form>
                <input 
                className='search input_table'
                type="search"
                maxLength={10}
                placeholder={this.state.selectedOption === 'name'? 'Szukaj zlecenie' : 'Szukaj w-g daty'}
                value={this.state.searchTerm}
                onChange={(e) => {this.handleSearch(e.target.value); this.props.listItemEdit(0)}}
              />
              </div>
              <div className='serch-chekbox'>
                <input
                  type="checkbox"
                  id="scales"
                  name="scales"
                  checked={this.state.checkboxState}
                  onChange={this.handleCheckboxChange}
                />
                <label htmlFor="scales">szukać we wszystkich tablicach</label>
              </div>
              </div>
          </div>
          {table()}
                    <Modal
        isOpen={this.state.ModalIsOpen}
        onRequestClose={() => this.setState({ModalIsOpen:false})}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          },
          content: {
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            border: 'none',
            background: 'none',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',
            padding: '0',
            zIndex: '1000',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }
        }}
      >
        {/* Content of your modal */}
        <div className='email_modal' style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px' }}>
          <h2>Wyślij tablice po email</h2>
            <Typeahead
                id="email-input"
                labelKey="email"
                options={this.state.emails}
                placeholder="Wpisz adres email"
                onChange={(selected) => this.handleEmailChangeMail(selected)}
                onInputChange={(text) => this.handleInputChangeMail(text)}
                emptyLabel=""
            />
          <input
                className='input_mail form-control'
                type="email"
                placeholder='Dodaj komentarz (opcjonalnie)'
                value={this.state.bodyTxt}
                onChange={this.handleInputBody}
                />
          <div>
            <button onClick={() => {this.setState({ModalIsOpen:false});
                                    this.setState({selectedEmail:''});
                                    this.setState({bodyTxt:''});} }><BiX /></button>
            {this.state.selectedEmail !== '' ? <button className='akcept' onClick={() => this.takeScreenshot()}><BiMailSend /></button> : <></>}
          </div>

        </div>
      </Modal>
        </div>
        );
    }
  }



export default WorkTable;
 