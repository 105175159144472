import { Component } from 'react';
import './palety.css'

class AppPalety extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
      }
    // this.props.callBackPalety({this.calculateSubcountSum()})
    calculateSubcountSum = () => {
    
        let sum = 0;
      
        // Проходження по всіх об'єктах у масиві
        this.props.data.forEach((obj) => {
          // Перетворення значення "subcount" в число та додавання до суми
          sum += parseInt(obj.subcount, 10) || 0;
        });

        return sum;

      }

    render() {
    
        return (
            <>
              <div className='palety'>Ilość palet: {this.calculateSubcountSum()} </div>
            </>
          )
    }
    
}

export default AppPalety;