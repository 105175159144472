import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import PrintWz from './print-doc'; // Імпортуйте ваш компонент PrintWz
import PrintCmr from './cmr-doc';  // Імпортуйте ваш компонент PrintCmr


const PrintButton = ({ data, typeDoc }) => {
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <div>
            <button onClick={handlePrint}>Drukuj</button>
            {/* Рендеримо відповідний компонент на основі пропсу typeDoc */}
            {typeDoc === 'wz' ? (
                <PrintWz ref={componentRef} data={data} />
            ) : (
                <PrintCmr ref={componentRef} data={data} />
            )}
        </div>
    );
}

export default PrintButton;
