import { Component } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import WorkTable from './work-tabl/work-table';


import './work-place.scss';



class  WorkPlace extends Component {
    constructor(props){
        super(props);
        this.state = {
        newRow: {
            id: '',
            name: '',
            title: '',
            count: '',
            subcount: '',
            order: 'actuality',
          }
        }
      
    }
    render() {
        return (
          <div className='work__place'>
            <WorkTable
                host={this.props.host}
                listItemEdit={this.props.listItemEdit}
                listItem={this.props.listItem} 
                messageCallback={this.props.messageCallback}
                messageData={this.props.messageData}
                listData = {this.props.listData}
                allData = {this.props.allData}
                deleteById = {this.props.deleteById}
                newUpData={this.props.newUpData}
                upData={this.props.upData}
                data={this.props.data}
                deleteBD = {this.props.deleteBD}
                editItem = {this.props.editItem}
                user = {this.props.user}

            />
          </div>
    );
    }
}
export default WorkPlace;