import React, { useState } from "react";
import Auth from "./login-components/auth";
import Register from "./login-components/register";

const Login = (props) => {
  const [register, setRegister] = useState(false);

  const display = () => {
    return register ? (
      <Register />
    ) : (
      <Auth giveToken={props.giveToken} giveUser={props.giveUser}/>
    );
  };

  return <>{display()}</>;
};

export default Login;
