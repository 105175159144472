import React, { useState } from "react";
import axios from 'axios';

const Register = () => {
    const [pass, setPass] = useState("");
    const [username, setUserName] = useState("");


    
const registerToApp = (data) => {
    axios.post('https://api.magazyn-stan.fun/api/register', data, ) // Замініть URL на правильний шлях до вашого серверу
      .then(response => {

      })
      .catch(error => {

      });
  };

  const handleLogin = () => {
    const data = { username: username, pass: pass };
    registerToApp(data);
  }
    return (
        <>
         <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h2 className="card-title">Zarejestruj się</h2>
                <form>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label"></label>
                    <input
                      type="username"
                      className="form-control"
                      placeholder="Login"
                      id="username"
                      value={username}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Haslo"
                      id="password"
                      value={pass}
                      onChange={(e) => setPass(e.target.value)}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleLogin}
                  >
                    Увійти
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
        
    )
}

export default Register;
