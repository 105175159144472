
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as math from 'mathjs'; 
import './cmr.scss';

class PrintCmr extends Component {
    evaluate = (inputValue) => {
        try {
            const parts = inputValue.split('=');
            const expressionBeforeEquals = parts[0];
            const cleanedExpression = expressionBeforeEquals.replace(/[^()\d/*+.]/g, '');
            const result = math.evaluate(cleanedExpression);
            return typeof result === 'number' && !isNaN(result) ? result : '';
        } catch (error) {
            return '';
        }
    };
    calculateSubcountAll = (data) => {
        let sum = 0;
      
        // Проходження по всіх об'єктах у масиві
        data.forEach((obj) => {
          // Перетворення значення "subcount" в число та додавання до суми
          sum += parseInt(obj.subcount, 10) || 0;
        });
        return sum;
      }

    render() {
        let year = new Date().getFullYear(),
            mouth = new Date().getMonth() + 1,
            day = new Date().getDate();
        let formatDay = day < 10 ?  "0"+day : day,
            formatMouth = mouth < 10 ?  "0"+mouth : mouth
        const column = this.props.data.map((item) => (
                <li key={item._id}>{item.name} - {this.evaluate(item.count)}ex.</li>

        ));
        
        return (
            <div className='body-cmr'>
            <div className="cmr-body">
             <div>Copy for sender</div>
            <div className="bigNum">1</div>
            
            <table  className="top-table">
              <tbody>
                <tr>
                  <td colSpan="2" className="height-65">
                    <div className="num">1</div>
                    <div>
                      Sender (name, address, country)
                      <div className="sender-details">
                        CGS Drukarnia sp. z o.o. 
                        ul. Towarowa 3<br />
                        Mrowino k./Poznania<br />
                        Tel: +48 61 896 06 60
                        Tel: +48 61 896 06 62<br />
                        NIP: 779-16-32-331
                      </div>
                    </div>
                  </td>
                  <td colSpan="2">
                    <div>INTERNATIONAL CONSIGNMENT</div>
                    <div className="cmr-logo">
                      <img src="https://www.maro-pack.pl/assets/images/cmr.jpg" alt="CMR Logo" />
                    </div>
                    <p>NR:</p>
                    <div>This carriage is subject to the Convention on the Contract for the international Carriage of goods by road (CMR).</div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" className="height-65">
                    <div className="num">2</div>
                    <div>
                     Receiver (name, address, country) <br/>
                      <textarea className="area-adres"/>
                    </div>
                  </td>
                  <td colSpan="2" className="border-black">
                    <div className="num">16</div>
                    <div>
                      Carrier (Name, address, country)
                    </div>
                    <div className="carrier-notes">
                      NR. REJ.
                    </div>
                  </td>
                </tr>
                <tr className='cmr-to_adress'>
                  <td colSpan="2" className="height-50">
                    <div className="num ">3</div>
                    <div className="sender-details">
                      <p>Place of delivery of the goods (place, country)</p>
                        <textarea className="area-adres"/>
                    </div>
                  </td>
                  <td colSpan="2" className="border-black">
                    <div className="num">17</div>
                    <div>
                      Successive carriers (name, address, country)
                    </div>
                  </td>
                </tr>
                <tr className='cmr-to_adress' >
                  <td colSpan="2" className="border-black height-35 sender-details">
                    <div className="num">4</div>
                    <div>
                      <p>Place and date taking over the goods (place, country, date)</p>
                      <div>CGS Drukarnia sp. z o.o.  ul. Towarowa 3<br /> Mrowino k./Poznania. Data: {`${formatDay}.${formatMouth}.${year}`}</div>
                    </div>
                  </td>
                  <td colSpan="2" className="border-black">
                    <div className="num">18</div>
                    <div>
                      Carrier’s reservations and observations
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" className="border-black height-35 sender-details">
                    <div className="num">5</div>
                    <div>
                     <p>Documents attached</p> 
                     <textarea className="area-adres"/>
                    </div>
                  </td>
                  <td colSpan="2" className="border-black"></td>
                </tr>
                <tr>
                  <td colSpan="4" className="height-180">
                    <table className="inner-table">
                      <tbody>
                        <tr>
                          <td><div className="num">6</div> Marks and Nos</td>
                          <td><div className="num">7</div> Number of packages</td>
                          <td><div className="num">8</div> Method of packing</td>
                          <td><div className="num">9</div> Nature of the goods</td>
                          <td className="border-black">
                            <div className="num">10</div> Statistical number</td>
                          <td className="border-black"><div className="num">11</div> Gross weight in kg</td>
                          <td className="border-black"><div className="num">12</div> Volume in m3</td>
                        </tr>
                        <tr className="height-150">
                          <td colSpan="4" className="border-black">
                            <ul className='cmr-list'>{column}</ul>
                          </td>
                          <td className="border-black cmr-palsumm">{this.calculateSubcountAll(this.props.data)} palet</td>
                          <td className="border-black cmr-palsumm"><textarea className="area-date"/></td>
                          <td className="border-black cmr-palsumm"><textarea className="area-date"/></td>
                        </tr>
                        <tr >
                          <td colSpan="4">
                            <table className="inner-table">
                              <tbody>
                                <tr>
                                  <td>Number UN</td>
                                  <td className="border-black">Look point 9</td>
                                  <td className="border-black">Class</td>
                                  <td className="border-black">The packing group</td>
                                  <td className="border-black">( ADR* )</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td className="border-black"></td>
                          <td className="border-black"></td>
                          <td className="border-black"></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr className='cmr-instrukcja'>
                  <td colSpan="2" className="width-400">
                    <table className="inner-table">
                      <tbody>
                        <tr>
                          <td className="border-black height-180">
                            <div className="num">13</div>
                            <div>
                              Sender’s instructions <br/>
                                <textarea className="area-adres"/>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="border-black height-65">
                            <div className="num">14</div>
                            <div>
                              Instruction as to payment for carriage
                            </div>
                            <div>Carriage paid / Carriage forward</div>
                          </td>
                        </tr>
                        <tr>
                          <td className="border-black height-45">
                            <div className="num">21</div>
                            <p>Established in:</p><p>on:</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td colSpan="2">
                    <table className="inner-table">
                      <tbody>
                        <tr>
                          <td className="height-40">
                            <div className="num">19</div> Special provisions
                          </td>
                        </tr>
                        <tr>
                          <td className="height-116">
                            <table className="border-black width-100">
                              <tbody>
                                <tr>
                                  <td className="border-black"><div className="num">20</div> To be paid by</td>
                                  <td className="border-black">Sender</td>
                                  <td className="border-black"></td>
                                  <td colSpan="2" className="border-black">Currency</td>
                                  <td className="border-black">Consignee</td>
                                </tr>
                                <tr>
                                  <td>Carriage charges</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>Deductions</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>Balance</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>Supplementary charges</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>Miscellaneous</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td className="height-45">Method of payment</td>
                                  <td colSpan="5"></td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr className='cmr-footer'>
                  <td colSpan="2">
                    <div className="num">22</div>
                    <div>Signature and stamp of sender</div>
                    <div className="sign-stamp"></div>
                  </td>
                  <td>
                    <div className="num">23</div>
                    <div>Signature and stamp of carrier</div>
                    <div className="sign-stamp"></div>
                  </td>
                  <td>
                    <div className="num">24</div>
                    <div>Signature and stamp of consignee</div>
                    <div className="sign-stamp"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>
  
        );
    }
}

export default PrintCmr;




